
import { useSelector } from 'react-redux';
import React, {useState} from 'react';
import {Input, Form, Select, Switch} from 'antd';
import { Trans } from '@lingui/macro';
import { MenuItemContainer } from '../MenuItemContainer';
import { OptionSelect } from 'components/OptionSelect';
import { selectReferenceData } from 'selectors/referenceData';
import filterOption from 'components/FilterCars/utils/filterOption';
import { LanguageType } from 'types/language';
import {FieldsProps, ReviewSource} from '../types';
import { LangWarning } from '../styled';

export const BasicInfoFields: React.FC<FieldsProps> = ({
	isLoading,
	getFieldDecorator,
	defaultValues,
	editing,
}) => {
	const referenceData = useSelector(selectReferenceData);

	const reviewSourceOptions = [
		{ label: 'Google', value: ReviewSource.GOOGLE },
		{ label: 'Seznam', value: ReviewSource.SEZNAM },
		{ label: 'Facebook', value: ReviewSource.FACEBOOK },
	];

	const [currentReviewSource, setCurrentReviewSource] = useState(defaultValues.reviewSource);
	const [currentReviewSourceLink, setCurrentReviewSourceLink] = useState(defaultValues.reviewSourceLink);

	const handleReviewSourceChange = (e: ReviewSource | null) => {
		setCurrentReviewSource(e);
	};
	const handleReviewSourceLinkChange = (e: { target: { value: string | null; }; }) => {
		setCurrentReviewSourceLink(e.target.value);
	};


	return (
		<>
			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_LABEL_CUSTOMER_NAME" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('customerName', {
						initialValue: defaultValues.customerName,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(<Input />)}
				</Form.Item>
			</MenuItemContainer>

			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_LABEL_COUNTRY" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('country', {
						initialValue: defaultValues.country,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(
						<OptionSelect
							options={referenceData.countryOptions}
							placeholder={<Trans id="FILTER_COUNTRY_PLACEHOLDER" />}
						/>,
					)}
				</Form.Item>

			</MenuItemContainer>
			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_LABEL_LANGUAGE" />}
				isLoading={isLoading}
			>
				jazyk
				<Form.Item>
					{getFieldDecorator('language', {
						initialValue: defaultValues.language,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(
						<Select
							showSearch
							placeholder={<Trans id="FEED_LANG_LABEL" />}
							filterOption={filterOption}
						>
							{referenceData.languageOptions.map(
								(option: LanguageType) => (
									<Select.Option disabled={editing ? true : undefined} key={option.code} value={option.id}>
										{option.name}
									</Select.Option>
								),
							)}
						</Select>,
					)}
					<LangWarning><Trans id="REVIEWS_LANG_CHANGE" /></LangWarning>
				</Form.Item>
			</MenuItemContainer>
			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_VERIFIED_PURCHASE" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('verifiedBuyer', {
						valuePropName: 'checked',
						initialValue: defaultValues.verifiedBuyer,
					})(<Switch />)}
				</Form.Item>
			</MenuItemContainer>
			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_REVIEW_SOURCE_ICON" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('reviewSource', {
						initialValue: defaultValues.reviewSource,
						rules: [
							{
								required: currentReviewSourceLink,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
						onChange: handleReviewSourceChange,
					})(
						<OptionSelect
							allowClear
							options={reviewSourceOptions}
							placeholder={<Trans id="FILTER_COUNTRY_PLACEHOLDER" />}
						/>,
					)}
				</Form.Item>
			</MenuItemContainer>
			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_REVIEW_SOURCE_URL" />}
				isLoading={isLoading}
			>

				<Form.Item>
					{getFieldDecorator('reviewSourceLink', {
						initialValue: defaultValues.reviewSourceLink,
						rules: [
							{
								required: currentReviewSource,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
						onChange: handleReviewSourceLinkChange,
					})(<Input />)}
				</Form.Item>
			</MenuItemContainer>
		</>
	);
};
