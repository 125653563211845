import { LanguageType } from 'types/language';
import { ModelFamilyType } from '../../types/modelFamily';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile } from 'antd/lib/upload/interface';

interface IdProp {
	readonly id: number;
}

export enum ReviewSource {
	GOOGLE = 'google',
	SEZNAM = 'seznam',
	FACEBOOK = 'facebook',
}

export interface ReviewProps {
	readonly answer: string | null;
	readonly id: string;
	readonly language: LanguageType;
	readonly review: string;
}

export interface ModifiedReview extends Omit<ReviewProps, 'language'> {
	readonly language: number;
	readonly languageName: string;
}

interface CreateBy {
	readonly id: string;
	readonly email: string;
}

export interface Photo {
	readonly created_at: string;
	readonly created_by: CreateBy;
	readonly description: string;
	readonly file_mime_type: string;
	readonly file_name: string;
	readonly file_size: number;
	readonly id: string;
	readonly namespace: string;
	readonly storage_service_id: string;
}

export interface FormValues {
	readonly id?: string;
	readonly customerName: string;
	readonly review: string;
	readonly answer: string;
	readonly visibility: boolean;
	readonly isPrioritized: boolean;
	readonly country: IdProp | null;
	readonly language: IdProp | null;
	readonly recommendsCarvago: boolean;
	readonly modelFamily: IdProp | null;
	readonly verifiedBuyer: boolean;
	readonly reviewSource: ReviewSource | null;
	readonly reviewSourceLink: string | null;
	readonly yearOfManufacture: number;
	readonly rating: number;
	readonly homepage: boolean;
	readonly translations?: ReviewProps[];
	readonly make?: IdProp | null;
	readonly 'translations-1'?: string;
	readonly 'translations-2'?: string;
	readonly 'translations-6'?: string;
	readonly 'translations-7'?: string;
	readonly 'translations-answer-1'?: string;
	readonly 'translations-answer-2'?: string;
	readonly 'translations-answer-6'?: string;
	readonly 'translations-answer-7'?: string;
	readonly photo: Photo | null;
	readonly orderingNumber: number | null;
}
export interface FieldsProps {
	readonly isLoading: boolean;
	readonly getFieldDecorator: any;
	readonly defaultValues: FormValues;
	readonly setFieldsValue?: any;
	readonly editing?: boolean;
	readonly form?: any;
}
export interface ModelOptionType {
	readonly value: number | string;
	readonly label: string;
	readonly nested?: boolean;
}

export interface CarInfoProps {
	readonly isLoading: boolean;
	readonly defaultValues: FormValues;
	readonly modelData: ModelFamilyType[] | null;
	readonly getFieldDecorator: any;
	readonly setFieldsValue: (value: any) => void;
}

export interface PhotoProps {
	readonly uid: string;
	readonly url: string;
	readonly name: string;
	readonly size: number;
	readonly type: string;
}

export interface UploadCardProps {
	readonly data?: any;
	readonly setFileList: (fileList: (state: RcFile[]) => RcFile[]) => void;
	readonly fileList: UploadFile[];
	readonly apiLoading: boolean;
}
